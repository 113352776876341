import { getAimsUrlFlavor } from 'lib/getAimsUrl';
import { isAimsUrl } from 'lib/isAimsUrl';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  getTelemundoImageSrc,
  getCloudinaryImageSrc,
  isCloudinaryImage,
  isResizableImage,
} from 'lib/imageUtils';
import { verticalSiteConfigs } from 'lib/verticalSiteConfigs';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  PROCESS_TELEMUNDO_IMAGE,
} from 'lib/brandFeatures';

const aimsFlavorFor = (platform, vertical) => {
  const isVerticalTodayDotCom = (vertical === 'today');

  switch (platform) {
    case 'og':
      return isVerticalTodayDotCom
        ? AIMS_FLAVORS.SOCIAL_SHARE_OG_TODAY
        : AIMS_FLAVORS.SOCIAL_SHARE_OG;
    case 'twitter':
      return isVerticalTodayDotCom
        ? AIMS_FLAVORS.SOCIAL_SHARE_TWITTER_TODAY
        : AIMS_FLAVORS.SOCIAL_SHARE_TWITTER;
    default:
      return AIMS_FLAVORS.SOCIAL_SHARE_DEFAULT;
  }
};

const attribution = (profile = null, vertical = null) => {
  if (profile?.twitter) {
    const url = profile.twitter.replace(/\/+$/, '');
    return url.substr(url.lastIndexOf('/') + 1);
  }
  if (vertical === 'msnbc') {
    return 'MSNBC';
  }
  if (vertical === 'noticias') {
    return 'TelemundoNews';
  }
  if (['entretenimiento', 'shows', 'telemundo', 'deportes'].includes(vertical)) {
    return 'Telemundo';
  }
  return 'NBCNews';
};

const defaultImage = (vertical) => {
  switch (vertical) {
    case 'today':
    // eslint-disable-next-line max-len
      return 'https://nodeassets.nbcnews.com/cdnassets/projects/today-socialshareimages-bento/Today-og-image.png';
    case 'noticias':
    // eslint-disable-next-line max-len
      return 'https://media4.s-nbcnews.com/i/newscms/2020_04/3196111/telemundo_social_image_e00f8bf16b45eaa533c946692771742d.png';
    case 'select':
    // eslint-disable max-len
      return 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/nbc-select/fallback/default-blue-1200x630.png';
    // eslint-enable max-len
    case 'msnbc':
    // eslint-disable-next-line max-len
      return 'https://media-cldnry.s-nbcnews.com/image/upload/t_nbcnews-fp-1200-630/newsgroup-logos/msnbc/social/default-black-1200x600.png';
    default:
    // eslint-disable max-len
      return 'https://media-cldnry.s-nbcnews.com/image/upload/v1696280772/newsgroup-logos/nbcnews/social/primary-white-1680x840.png';
    // eslint-enable max-len
  }
};

const shareImage = (imageUrl, vertical, platform = null) => {
  if (!isResizableImage(imageUrl)) {
    return defaultImage(vertical);
  }

  if (isAimsUrl(imageUrl)) {
    return imageUrl;
  }

  if (isCloudinaryImage(imageUrl)) {
    return getCloudinaryImageSrc(imageUrl, aimsFlavorFor(platform, vertical));
  }

  if (getFeatureConfigForBrand(PROCESS_TELEMUNDO_IMAGE, vertical)) {
    return getTelemundoImageSrc(imageUrl, aimsFlavorFor(platform, vertical));
  }

  return getAimsUrlFlavor(imageUrl, { flavor: aimsFlavorFor(platform, vertical) });
};

const sanitize = (profile) => {
  if (profile) {
    return profile.replace('@', '').trim().split('/').pop();
  }
  return null;
};

const profileURL = {
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  pinterest: 'https://www.pinterest.com/',
  snapchat: 'https://www.snapchat.com/add/',
  tiktok: 'https://www.tiktok.com/@',
  twitter: 'https://x.com/',
  whatsapp: 'https://wa.me/',
  youtube: 'https://www.youtube.com/user/',
  threads: 'https://www.threads.net/@',
};

const reTrim = /\/$/m;
const reChars = /(\/|@)/m;
const reNums = /[^\d]/g;

const getProfileURL = (network, handle) => {
  if (network === 'mastodon') { return handle; }

  if (typeof network === 'string'
  && typeof profileURL[network] !== 'undefined'
  && typeof handle === 'string'
  && handle.length) {
    let trimmed = handle.trim().replace(reTrim, '');
    const splitOnInvalidChars = trimmed.split(reChars);
    if (Array.isArray(splitOnInvalidChars) && splitOnInvalidChars.length > 0) {
      trimmed = splitOnInvalidChars[splitOnInvalidChars.length - 1];
      if (network === 'whatsapp') {
        return `${profileURL[network]}${trimmed.replace(reNums, '')}`;
      }
    }
    return `${profileURL[network]}${trimmed}`;
  }
  return null;
};

const buildBrandSocials = (vertical) => {
  const socials = verticalSiteConfigs[vertical];
  return [
    socials.twitter,
    socials.pinterest,
    socials.facebook,
    socials.instagram,
    socials.youtube,
    socials.snapchat,
    socials.tiktok,
  ].filter((e) => e); // filter nulls and empty values
};

export {
  aimsFlavorFor,
  attribution,
  defaultImage,
  getProfileURL,
  sanitize,
  shareImage,
  buildBrandSocials,
};
