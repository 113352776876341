/**
 * Transform blog card page URLs to hash link urls
 * @param {string} cardPageURL
 * @returns {string}
 */
export function transformCardLinkToHashLink(cardPageURL) {
  try {
    const {
      origin,
      pathname,
      search,
    } = new URL(cardPageURL);

    const cardPathPattern = new RegExp('(?:/)([a-zA-Z]crd[0-9].*)$');

    const matches = pathname.match(cardPathPattern);
    if (!matches) {
      return cardPageURL;
    }

    const [cardPath, cardId] = matches;
    const canonicalPathname = pathname.replace(cardPath, '');
    const cardHash = `#${cardId}`;
    const url = new URL(`${origin}${canonicalPathname}${search}${cardHash}`);

    return url.toString();
  } catch (e) {
    console.error(e);

    return cardPageURL;
  }
}
